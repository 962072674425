import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { useSidebar } from '~/contexts/sidebar'
import { DrawerHeader } from './components/Sidebar/style'
import { Collapsibles, Navbar, Sidebar } from './components'
import { usePermission } from '~/hooks'
import { useAuth } from '~/contexts'
import {
  IconBolt,
  IconBuilding,
  IconBuildingBank,
  IconCategory,
  IconCurrencyDollar,
  IconFileCertificate,
  IconHeartHandshake,
  IconSettings,
  IconSolarPanel,
  IconUser,
  IconUsers
} from '@tabler/icons-react'

export const drawerWidth = 270

export type TSidebarItem = {
  text: string
  icon: React.JSX.Element
  path?: string
  collapsibleName?: keyof Collapsibles
  nested?: {
    text: string
    icon: React.JSX.Element
    path: string
  }[]
}

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { authUser } = useAuth()
  const { isVisible, toggleSidebar } = useSidebar()

  const isAdmin = authUser?.accessControlRef.name === 'Admin'
  const canEditDealerships = usePermission(['dealership.updated', 'dealership.create'])
  const canEditPowerPlants = usePermission(['powerPlants.updated', 'powerPlants.create'])
  const canEditPowerGenerators = usePermission(['powerGenerator.updated', 'powerGenerator.create'])
  const canEditConsortium = usePermission(['consortiums.updated', 'consortiums.create'])
  const canEditClients = usePermission(['customers.updated', 'customers.create'])
  const canReadClients = usePermission(['customers.read'])
  const canEditAccessControl = usePermission(['accessControl.updated', 'accessControl.create'])
  const canEditUsers = usePermission(['users.updated', 'users.create'])
  const canEditContracts = usePermission(['contracts.updated', 'contracts.create'])

  const sidebarItems: TSidebarItem[] = [
    { text: 'Visão geral', icon: <IconCategory />, path: '/app' },
    {
      text: 'Vendas', icon: <IconCurrencyDollar />, collapsibleName: 'proposals', path: '/app/proposals/*',
      nested: [
        { text: 'Propostas', icon: 'User', path: '/app/proposals' },
        { text: 'Cadastrar proposta', icon: 'User', path: '/app/proposals/register' },
        { text: 'Analisar Documentos', icon: 'User', path: '/app/proposals/analysis' }
      ].filter(item => !!item)
    },
    {
      text: 'Contratos', icon: <IconFileCertificate />, collapsibleName: 'contracts', path: '/app/contracts/*',
      nested: [
        { text: 'Lista de contratos', icon: <IconUser />, path: '/app/contracts' },
        canEditContracts && { text: 'Gerar contrato', icon: 'User', path: '/app/contracts/create' },
      ].filter(item => !!item)
    },
    {
      text: 'Concessionárias', icon: <IconBuilding />, collapsibleName: 'dealerships', path: '/app/dealerships/*',
      nested: [
        { icon: 'User', text: 'Concessionárias cadastradas', path: '/app/dealerships' },
        canEditDealerships && { icon: 'User', text: 'Cadastrar Concessionária', path: '/app/dealerships/create' }
      ].filter(item => !!item)
    },
    {
      text: 'Geradores', icon: <IconBolt />, collapsibleName: 'generators', path: '/app/generators/*',
      nested: [
        { icon: 'User', text: 'Geradores cadastrados', path: '/app/generators' },
        canEditPowerGenerators && { icon: 'User', text: 'Cadastrar Gerador', path: '/app/generators/create' }
      ].filter(item => !!item)
    },
    {
      text: 'Usinas', icon: <IconSolarPanel />, collapsibleName: 'powerPlant', path: '/app/power-plants/*',
      nested: [
        { icon: 'User', text: 'Usinas cadastradas', path: '/app/power-plants' },
        canEditPowerPlants && { icon: 'User', text: 'Cadastrar Usina', path: '/app/power-plants/create' },

      ].filter(item => !!item)
    },
    {
      text: 'Consórcio', icon: <IconHeartHandshake />, collapsibleName: 'consortiums', path: '/app/consortiums/*',
      nested: [
        { icon: 'User', text: 'Consórcios cadastrados', path: '/app/consortiums' },
        canEditConsortium && { icon: 'User', text: 'Cadastrar Consórcio', path: '/app/consortiums/create' }
      ].filter(item => !!item)
    },
    (canReadClients || canEditClients) && {
      text: 'Clientes', icon: <IconUser />, collapsibleName: 'clients', path: '/app/customers/*',
      nested: [
        canReadClients && { text: 'Clientes cadastrados', path: '/app/customers' },
        canEditClients && { text: 'Cadastrar cliente', path: '/app/customers/create' }
      ].filter(item => !!item)
    },
    {
      text: 'Financeiro', icon: <IconBuildingBank />, collapsibleName: 'financials', path: '/app/financials/*',
      nested: [
        { text: 'Faturas', path: '/app/financials' },

      ].filter(item => !!item)
    },
    {
      text: 'Parceiros', icon: <IconUsers />, collapsibleName: 'partners', path: '/app/partners/*',
      nested: [
        { text: 'Parceiros cadastrados', path: '/app/partners' },
        { text: 'Cadastrar parceiro', path: '/app/partners/create' },

      ].filter(item => !!item)
    },
    isAdmin && {
      text: 'Configurações', icon: <IconSettings />, collapsibleName: 'settings', path: '/app/settings', nested: [
        { icon: 'User', text: 'Perfis do sistema', path: '/app/access-control' },
        canEditAccessControl && { icon: 'User', text: 'Criar Perfil', path: '/app/access-control/create' },
        { icon: 'User', text: 'Usuários', path: '/app/users' },
        canEditUsers && { icon: 'User', text: 'Criar usuário', path: '/app/users/create' }
      ].filter(item => !!item)
    }
  ].filter(item => !!item) as TSidebarItem[]

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar isDrawerOpen={isVisible} toggleDrawer={toggleSidebar} />
      <Sidebar toggleSidebar={toggleSidebar} sidebarItems={sidebarItems} isDrawerOpen={isVisible} />
      <Box component='main' sx={{ flex: 1, overflowX: 'hidden', padding: 3, backgroundColor: '#FAFAFA', height: '100vh' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
