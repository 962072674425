import { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { clearString, onlyNumbers, theme, transformMoney } from '~/utils'
import { Annotations, ProposalInfo, PropsWithWizard } from '~/components'
import { Form } from '~/components/Form/Form'
import { useUploadMultiFiles } from '~/hooks'
import { useProposalContext } from '~/contexts'
import { AddressForm } from '../Address/utils'
import { Option, defaultOption } from '~/utils/options'
import { DragAndDrop } from '~/components/Form/DragAndDrop'
import { addressSchema, fileSchema } from '~/utils/yupSchema'
import { ActionButtons, EnergyBillFields, HasPendenciesWarning, NotSendAllDocumentosWarning, PersonTypeDocuments } from './components'
import { EConsumerClassification, ECustomerPersonType, ECustomerTension, EProposalFileStatus, EProposalFileType, EProposalStatus, ETariffClassification, ProposalAddEnergyBillInfoGraphqlDto, ProposalModel, useDealershipListQuery, useProposalAddEnergyBillInfoMutation } from '~/graphql/types'
import { getCanFormalizeProposal } from '~/utils/proposal'

export type DocumentationForm = {
  tusdValue?: number
  tension: ECustomerTension
  energyTaxes?: number
  AmountCents?: number
  clientNumber: string
  dealershipId: string
  distributionTaxes?: number
  avarageConsumption: string
  installationNumber: string
  invoiceDueDay?: number
  tariffClassification: ETariffClassification
  address: AddressForm | null
  energyBill?: File[] | null,
  consumerClassification: EConsumerClassification
  customerPfDocumentWithPhotoBack?: File[] | null
  customerPfDocumentWithPhotoFront?: File[] | null
  customerPjDocumentCard?: File[] | null
  customerPjSocialContract?: File[] | null
}

const schema = yup.object().shape({
  address: addressSchema,
  tusdValue: yup.number(),
  tension: yup.string().required(),
  energyTaxes: yup.number(),
  AmountCents: yup.number(),
  clientNumber: yup.string(),
  dealershipId: yup.string(),
  distributionTaxes: yup.number(),
  installationNumber: yup.string(),
  invoiceDueDay: yup.number().max(31).min(1).required(),
  avarageConsumption: yup.string(),
  tariffClassification: yup.string().required(),
  energyBill: fileSchema,
  customerPjDocumentCard: fileSchema,
  customerPjSocialContract: fileSchema,
  customerPfDocumentWithPhotoBack: fileSchema,
  customerPfDocumentWithPhotoFront: fileSchema,
  consumerClassification: yup.string()
})

export const Documentation: React.FC<PropsWithWizard> = (props) => {
  const { proposal, setProposal } = useProposalContext()
  const [filteredDealerships, setFilteredDealerships] = useState<Option[]>([defaultOption])
  const [proposalAddEnergyBillInfo, { loading: addEnergyBillLoading }] = useProposalAddEnergyBillInfoMutation()
  const [uploadMultiFiles, { loading: loadingFilesUpload }] = useUploadMultiFiles()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const canFormalizeProposal = useMemo(() => getCanFormalizeProposal(proposal, false), [proposal])

  const energyBillFile = proposal?.files?.find(item => item.type === EProposalFileType.energyBill)
  const customerBackDocument = proposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)
  const customerFrontDocument = proposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)
  const customerPjDocumentCardDocument = proposal?.files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)
  const customerPjSocialContractDocument = proposal?.files?.find(item => item.type === EProposalFileType.customerPjSocialContract)

  const { data: dealerships, loading: loadingDealerships } = useDealershipListQuery({
    variables: {
      params: {
        pageSize: 99999
      }
    }
  })

  const isLoading = loadingFilesUpload || addEnergyBillLoading
  const disableFields = proposal?.status !== EProposalStatus.documentation

  const methods = useForm<DocumentationForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const updateEnergyBillInfo = async (formData: DocumentationForm) => {
    if (proposal) {
      const {
        address,
        tension,
        tusdValue,
        AmountCents,
        energyTaxes,
        clientNumber,
        dealershipId,
        distributionTaxes,
        installationNumber,
        invoiceDueDay,
        avarageConsumption,
        tariffClassification,
        consumerClassification
      } = formData
      const dealershipName = dealerships?.dealershipList.data.find(dealership => dealership._id === dealershipId)?.name

      const formattedAddress = address ? {
        ...address,
        zipcode: clearString(address?.zipcode),
        country: 'Brasil',
      } : null

      const params: ProposalAddEnergyBillInfoGraphqlDto = {
        proposalId: proposal._id,
        clientNumber,
        dealershipId,
        dealershipName,
        installationNumber,
        invoiceDueDay,
        tariffClassification,
        consumerClassification,
        address: formattedAddress,
        tension,
        tusdValue: tusdValue ? transformMoney(tusdValue) : 0,
        AmountCents: AmountCents ? transformMoney(AmountCents) : 0,
        energyTaxes: energyTaxes ? transformMoney(energyTaxes) : 0,
        avarageConsumption: Number(onlyNumbers(avarageConsumption)),
        distributionTaxes: distributionTaxes ? transformMoney(distributionTaxes) : 0
      }

      const { data } = await proposalAddEnergyBillInfo({ variables: { params } })
      if (data) {
        setProposal({ ...proposal, ...data.proposalAddEnergyBillInfo } as ProposalModel)
      }
    } else {
      toast.error('Não conseguimos encontrar sua proposta :(', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  const uploadFiles = async (formData: DocumentationForm) => {
    const { customerPfDocumentWithPhotoBack, customerPfDocumentWithPhotoFront, energyBill, customerPjSocialContract, customerPjDocumentCard } = formData

    if (!isEmpty(customerPfDocumentWithPhotoBack) || !isEmpty(customerPfDocumentWithPhotoFront) || !isEmpty(energyBill) || !isEmpty(customerPjDocumentCard) || !isEmpty(customerPjSocialContract)) {
      if (proposal) {
        const filesToUpload: File[][] = []
        const fileTypes: EProposalFileType[] = []

        if (customerPjDocumentCard && !isEmpty(customerPjDocumentCard)) {
          filesToUpload.push(customerPjDocumentCard)
          fileTypes.push(EProposalFileType.customerPjDocumentCard)
        }

        if (customerPjSocialContract && !isEmpty(customerPjSocialContract)) {
          filesToUpload.push(customerPjSocialContract)
          fileTypes.push(EProposalFileType.customerPjSocialContract)
        }

        if (customerPfDocumentWithPhotoBack && !isEmpty(customerPfDocumentWithPhotoBack)) {
          filesToUpload.push(customerPfDocumentWithPhotoBack)
          fileTypes.push(EProposalFileType.customerPfDocumentWithPhotoBack)
        }

        if (customerPfDocumentWithPhotoFront && !isEmpty(customerPfDocumentWithPhotoFront)) {
          filesToUpload.push(customerPfDocumentWithPhotoFront)
          fileTypes.push(EProposalFileType.customerPfDocumentWithPhotoFront)
        }

        if (energyBill && !isEmpty(energyBill)) {
          filesToUpload.push(energyBill)
          fileTypes.push(EProposalFileType.energyBill)
        }

        const updatedProposal = await uploadMultiFiles({
          proposalId: proposal._id,
          types: fileTypes,
          files: filesToUpload
        })

        if (updatedProposal) {
          setProposal(updatedProposal)
          methods.clearErrors()
        }
      }
    }
  }

  const onSubmit = async (formData: DocumentationForm) => {
    try {
      await updateEnergyBillInfo(formData)
      await uploadFiles(formData)

      methods.setValue('energyBill', undefined)
      methods.setValue('customerPjDocumentCard', undefined)
      methods.setValue('customerPjSocialContract', undefined)
      methods.setValue('customerPfDocumentWithPhotoBack', undefined)
      methods.setValue('customerPfDocumentWithPhotoFront', undefined)

      toast.success('Documentos salvos com sucesso!', {
        autoClose: 3000,
        position: 'top-right'
      })
    } catch (err) {
      toast.error('Não foi possível realizar o upload de arquivos ou dados da fatura, entre em contato com o time de tecnologia :(', {
        autoClose: 3000,
        position: 'top-right'
      })
    }
  }

  useEffect(() => {
    if (dealerships && dealerships.dealershipList.data.length !== 0) {
      const mappedDealerships = dealerships.dealershipList.data.map(item => {
        return {
          label: item.name || '',
          value: item._id
        }
      })

      if (mappedDealerships.length !== 0) {
        const registeredDealershipId = proposal?.energyBillInfo?.dealershipId

        setFilteredDealerships(mappedDealerships)
        methods.setValue('dealershipId', registeredDealershipId ?? mappedDealerships[0].value)
      } else {
        methods.setValue('dealershipId', '')
        setFilteredDealerships([defaultOption])
      }
    }

    if (proposal) {
      const { energyBillInfo } = proposal

      if (energyBillInfo?.address) {
        methods.setValue('address.city', energyBillInfo.address.city)
        methods.setValue('address.complement', energyBillInfo.address.complement || '')
        methods.setValue('address.neighborhood', energyBillInfo.address.neighborhood)
        methods.setValue('address.number', energyBillInfo.address.number)
        methods.setValue('address.state', energyBillInfo.address.state)
        methods.setValue('address.street', energyBillInfo.address.street)
        methods.setValue('address.zipcode', energyBillInfo.address.zipcode)
      }
      methods.setValue('consumerClassification', energyBillInfo?.consumerClassification || '' as EConsumerClassification)
      methods.setValue('tension', energyBillInfo?.tension || '' as ECustomerTension)
      methods.setValue('dealershipId', energyBillInfo?.dealershipId || '')
      methods.setValue('clientNumber', energyBillInfo?.clientNumber || '')
      methods.setValue('installationNumber', energyBillInfo?.installationNumber || '')
      methods.setValue('invoiceDueDay', energyBillInfo?.invoiceDueDay || undefined)
      methods.setValue('tariffClassification', energyBillInfo?.tariffClassification || '' as ETariffClassification)
      methods.setValue('avarageConsumption', energyBillInfo?.avarageConsumption ? String(energyBillInfo?.avarageConsumption) : '')

      methods.setValue('tusdValue', energyBillInfo?.tusdValue ? transformMoney(energyBillInfo?.tusdValue, 'toReal') : 0)
      methods.setValue('AmountCents', energyBillInfo?.AmountCents ? transformMoney(energyBillInfo?.AmountCents, 'toReal') : 0)
      methods.setValue('energyTaxes', energyBillInfo?.energyTaxes ? transformMoney(energyBillInfo?.energyTaxes, 'toReal') : 0)
      methods.setValue('distributionTaxes', energyBillInfo?.distributionTaxes ? transformMoney(energyBillInfo?.distributionTaxes, 'toReal') : 0)
    }
  }, [dealerships, methods, proposal])

  useEffect(() => {
    if (energyBillFile?.status === EProposalFileStatus.rejected) {
      methods.setError('energyBill', { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
    if (customerBackDocument?.status === EProposalFileStatus.rejected) {
      methods.setError('customerPfDocumentWithPhotoBack', { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
    if (customerFrontDocument?.status === EProposalFileStatus.rejected) {
      methods.setError('customerPfDocumentWithPhotoFront', { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
    if (customerPjDocumentCardDocument?.status === EProposalFileStatus.rejected) {
      methods.setError('customerPjDocumentCard', { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
    if (customerPjSocialContractDocument?.status === EProposalFileStatus.rejected) {
      methods.setError('customerPjSocialContract', { message: 'Arquivo não atendeu os requisitos. Por favor, envie um novo arquivo.' })
    }
  }, [energyBillFile, customerBackDocument, customerFrontDocument, customerPjDocumentCardDocument, customerPjSocialContractDocument])

  // const onSearchCep = useCallback(async () => {
  //   if(addressWatch?.zipcode) {
  //     const { data, isSuccess } = await fetchCep(addressWatch.zipcode)
  //     if(isSuccess) {
  //       methods.setValue('address.state', data?.uf || '', { shouldValidate: true })
  //       methods.setValue('address.city', data?.localidade || '', { shouldValidate: true })
  //       methods.setValue('address.neighborhood', data?.bairro || '', { shouldValidate: true })
  //       methods.setValue('address.street', data?.logradouro || '', { shouldValidate: true })
  //     }
  //   }
  // }, [addressWatch])

  return (
    <FormProvider {...methods}>
      <Form>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          <ProposalInfo />

          <PersonTypeDocuments
            disabled={disableFields}
            personType={proposal?.customerRef.personType as ECustomerPersonType}
            customerBackDocument={customerBackDocument}
            customerFrontDocument={customerFrontDocument}
            customerPjDocumentCardDocument={customerPjDocumentCardDocument}
            customerPjSocialContractDocument={customerPjSocialContractDocument}
          />

          <Box flexDirection={isLowerSm ? 'column' : 'row'} display='flex' alignItems='baseline' gap={isLowerSm ? 1 : 2}>
            <Typography fontSize={isLowerSm ? '1rem' : '1.4rem'} variant='h3'>Fatura de energia</Typography>
            <Typography color='grey.400'>Informe os dados e anexe o arquivo da fatura de energia</Typography>
          </Box>

          <DragAndDrop
            disabled={disableFields}
            name='energyBill'
            $variant='secondary'
            label='Anexar última fatura de energia'
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 15MB)'
            status={energyBillFile?.status as EProposalFileStatus}
            sentAt={energyBillFile?.sendAt}
            $completed={energyBillFile?.sendAt}
            fileName={energyBillFile?.file?.fileName}
            fileUrl={energyBillFile?.file?.fileUrl}

          />

          <EnergyBillFields disableFields={disableFields} loadingDealerships={loadingDealerships} dealerships={filteredDealerships} />

          {proposal && <Annotations proposal={proposal} />}

          {proposal?.customerRef.hasPendencies && <NotSendAllDocumentosWarning />}
          {!canFormalizeProposal && !proposal?.customerRef.hasPendencies && <HasPendenciesWarning />}

          <ActionButtons onSubmit={methods.handleSubmit(onSubmit)} loading={isLoading} {...props} />
        </Box>
      </Form>
    </FormProvider>
  )
}
